<template>
  <div class="container">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
      <template #right v-if="articles.id == '5ea0cf5c-8ce5-4b4a-b0f6-4343a40af8e5'">
        <div @click="tohelp()">
          <van-icon name="/image/help32.png" size="25" />
        </div>
      </template>
    </van-nav-bar>

    <div class="NavTitle" v-if="HasBindList.length == 0">
      <h3 style="text-align: center; margin: 50px 10px;">
        请去绑定A3工号，再来查看海报。
      </h3>
      <van-button type="info"  size="large" block  @click="tobinda3">去绑定</van-button>
    </div>
      <Poster :questionnaireId="questionnaireId" v-if="HasBindList.length > 0"></Poster>
  </div>
</template>
<script>

import {
  GetA3UserInfo,
} from "@/api/UserToA3";
import { Toast } from "vant";
import { getQuestionnaire } from "@/api/questionnaire";
import Poster from "./components/poster";

//const key = "zn-history";
export default {
  name: "question-detail-type",
  data() {
    return {
      HasBindList: [],
      questionnaireId: "",
      scroll: 0,
      activeName: "a",
      title: "问卷详情",
      articles: {},
    };
  },
  components: {
    Poster,
  },
  activated() { },
  created() {
    this.initData();
  },
  updated() {
    //this.showQW();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/questiontype/detail") {
        if (to.query != from.query) {
          this.initData();
        }
      }
    },
  },

  methods: {
    async initData() {
      const data = await GetA3UserInfo();
      if (data.data.success) {
        this.HasBindList = data.data.data;
        if (this.HasBindList.length > 0) {
          //必须有A3工号
          this.questionnaireId = this.$route.query.id;
          let aresult = await getQuestionnaire(this.questionnaireId); //({ ...this.page })
          console.log(aresult);
          this.articles = aresult.data.data;
          this.title = this.articles.title;
        }
        else{
          this.title="缺少A3工号"
        }
      } else {
        Toast(data.data.errorMessage);
      }

    },
    tobinda3(){
      this.$router.push({
        path: "/user/binda3",
        query: {
          back: true
        },
      });
    },
    tohelp() {
      this.$router.push({
        path: "/bbs/detail",
        query: {
          para: '0f4e3cc1-e512-487c-8966-af63f4f266b4',
          type: 0,
          back: true
        },
      });
    }
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}

.zntitle {
  height: 30px;
}

.container {
  height: 100%;
  //overflow-y: auto;
  //box-sizing: border-box;

  /deep/.van-tabs {
    height: 100%;
  }

  /deep/.van-tabs__content {
    height: 100%;
  }
}

.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;

    .van-tab {
      line-height: 36px;
    }

    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }

  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }

  /deep/ .van-tab__pane {
    height: 100%;

    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.tabcontent {
  height: 100%;
  margin-bottom: 138px;
}</style>
